.btn {
  text-decoration: none;
  background: transparent;
  padding: 0.5rem;
  border-radius: 6px;
  border: 2px solid $primary-blue;
  @include mont-medium;
  font-size: 0.8rem;
  display: inline-block;
  &:hover {
    cursor: pointer;
    background: $primary-blue;
    color: white;
    transition: all 0.3s ease-in-out;
  }
}

.btn-inverse {
  text-decoration: none;
  background: $primary-blue;
  color: white;
  padding: 0.5rem;
  border-radius: 6px;
  border: 2px solid $primary-blue;
  @include mont-regular;
  font-size: 0.8rem;
  display: inline-block;
  &:hover {
    cursor: pointer;
    background: transparent;
    color: $primary-blue;
    transition: all 0.3s ease-in-out;
  }
}

.icon-btn {
  i {
    margin-right: 0.5rem;
  }
}
