@import "./config";
@import "./text-styles";
@import "./components";

@import "./skills.scss";
@import "./footer.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  overflow-x: hidden;
}

#hero {
  width: 100%;
  min-height: 100vh;
  #video-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    @media (max-width: $tablet-breakpoint) {
      display: none;
    }
    video {
      min-width: 100%;
      min-height: 100%;
    }
  }
  #header-overlay {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    background: $blue-gradient;
    z-index: 1;
    opacity: 0.6;
    @media (max-width: $phone-breakpoint) {
      opacity: 1;
    }
  }
  #text-overlay {
    position: absolute;
    top: 40%;
    left: 10%;
    transform: translateY(-50%);
    z-index: 2;
    @media (max-width: $phone-breakpoint) {
      display: none;
    }
    h1 {
      @include mont-bold;
      font-size: 7.5rem;
      line-height: 5rem;
      color: white;
      opacity: 0.1;
    }
  }
  #header-content {
    width: 70%;
    min-height: 60vh;
    position: relative;
    margin: 0 auto;
    display: flex;
    align-items: center;
    z-index: 2;
    @media (max-width: $tablet-breakpoint) {
      width: 90%;
      text-align: center;
    }
    #hero-text {
      flex-grow: 1;
      z-index: 2;
      position: absolute;
      top: 65%;
      @media (max-width: $tablet-breakpoint) {
        top: 40%;
      }
      h1 {
        @include mont-semibold;
        font-size: 3rem;
        color: $light-gray;
        @media (max-width: $phone-breakpoint) {
          font-size: 2rem;
        }
      }
      p {
        width: 500px;
        @include mont-light;
        font-size: 1rem;
        color: $light-gray;
        margin-top: 2rem;
        margin-bottom: 2rem;
        @media (max-width: $tablet-breakpoint) {
          width: 90%;
        }
      }
    }
    .btn {
      background: $international-orange;
      color: $light-gray;
      @include mont-semibold;
      border: none;
      padding: 0.6rem 1rem;
      transition: 0.5s;
      &:hover {
        background: $light-gray;
        color: $international-orange;
        transition: 0.2s;
      }
    }
  }
  #scroll-arrow {
    animation: pulse 3s ease-in-out infinite;
    color: $secondary-blue;
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    @media (max-width: $phone-breakpoint) {
      display: none;
    }
  }
}

#sideNav {
  position: fixed;
  height: 100vh;
  background: $light-gray;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  z-index: 5;
  @media (max-width: $tablet-breakpoint) {
    display: none;
  }
  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    li {
      font-size: 1.5rem;
      text-align: center;
      margin-bottom: 1.5rem;
      transition: all 0.3s ease-out;
      &:last-of-type {
        margin-bottom: 0;
      }
      a {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: $secondary-blue;
        i {
          opacity: 0;
          &:hover {
            color: $primary-blue;
            transition: 0.2s;
          }
        }
        span {
          visibility: hidden;
          opacity: 0;
          background: $light-gray;
          position: absolute;
          left: 50px;
          transform: translateX(20px);
          transition: 0.3s;
          @include mont-semibold;
          color: $primary-blue;
          font-size: 0.8rem;
          padding: 0.4rem 0.8rem;
          border-radius: 6px;
          min-width: 100px;
          &::before {
            content: "";
            position: absolute;
            top: 50%;
            right: 100%;
            margin-top: -6px;
            border-width: 6px;
            border-style: solid;
            border-color: transparent $light-gray transparent transparent;
          }
        }
        &:hover {
          span {
            visibility: visible;
            opacity: 1;
            transform: translateX(0);
            transition: 0.2s;
          }
        }
      }
    }
  }
}

//Mobile Navigation
#mobile-nav-container {
  position: fixed;
  width: 100vw;
  z-index: 50;
  display: none;
  @media (max-width: $tablet-breakpoint) {
    display: block;
    background: $light-gray;
  }
  #menu-btn {
    position: absolute;
    top: 20px;
    right: 40px;
    color: $primary-blue;
    font-size: 3rem;
  }
  #mobile-menu {
    // visibility: hidden;
    background: $light-gray;
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    z-index: 10;
    opacity: 0;
    transform: translateX(100%);
    transition: 0.4s ease;
    &.active {
      visibility: visible;
      opacity: 1;
      transform: translateX(0);
      transition: 0.4s ease;
    }
    .fa-times {
      font-size: 3rem;
      position: absolute;
      top: 20px;
      right: 40px;
      color: $primary-blue;
    }
    ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 100px;
      li {
        @include mont-semibold;
        font-size: 2.5rem;
        color: $primary-blue;
        margin-bottom: 2rem;
        a {
          color: inherit;
          text-decoration: none;
        }
      }
    }
    #menu-social-icons {
      position: absolute;
      bottom: 5%;
      left: 50%;
      transform: translateX(-50%);
      width: 60%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      a {
        color: $secondary-blue;
        i {
          font-size: 3rem;
        }
      }
    }
  }
}

#main-content {
  width: 70%;
  margin: 4rem auto 0;
  @media (max-width: $phone-breakpoint) {
    width: 90%;
  }
  #about-me {
    display: flex;
    align-items: center;
    @media (max-width: $phone-breakpoint) {
      flex-direction: column;
    }
    #avatar {
      flex-grow: 1;
      img {
        width: 60%;
        max-width: 340px;
        display: inline-block;
        margin: 0 auto;
      }
      @media (max-width: $phone-breakpoint) {
        width: 50%;
        margin: 0 auto;
        text-align: center;
      }
    }
    #about-me-text {
      flex-grow: 1;
      width: 50%;
      @media (max-width: $phone-breakpoint) {
        width: 80%;
        text-align: center;
      }
      p {
        @include mont-regular;
        color: $mid-gray;
        font-size: 1rem;
        margin-bottom: 1.5rem;
      }
      h5 {
        @include mont-bold;
        font-size: 2rem;
        margin-bottom: 1.5rem;
        color: $primary-blue;
        margin-top: 2rem;
        // &:last-of-type {
        //   margin-top: 2rem;
        //   margin-bottom: 0.75rem;
        // }
      }
      #cert-badges {
        margin-top: 2rem;
        img {
          max-width: 120px;
        }
      }
      a {
        color: lighten($mid-gray, 30%);
        margin-right: 0.5rem;
        transition: all 0.3s ease-out;
        i {
          font-size: 2rem;
        }
        &:hover {
          color: $mid-gray;
          transition: all 0.3s ease-in;
        }
      }
    }
  }

  #projects {
    margin: 10rem 0;
    text-align: center;
    @media (max-width: $phone-breakpoint) {
      margin: 4rem 0;
    }

    h1 {
      @include mont-bold;
      font-size: 6rem;
      opacity: 0.8;
      color: $primary-blue;
      margin-bottom: 6rem;
      @media (max-width: $phone-breakpoint) {
        font-size: 3rem;
      }
    }

    #projects-container {
      margin: 0 auto;
      @media (max-width: $phone-breakpoint) {
        flex-direction: column;
        width: 80%;
      }
      .project {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 4rem;
        @media (max-width: $phone-breakpoint) {
          flex-direction: column;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
        .project-information {
          text-align: left;
          margin-right: 4rem;
          @include mont-regular;
          @media (max-width: $phone-breakpoint) {
            margin-right: 0;
            text-align: center;
          }
          .project-logo {
            height: 4rem;
          }
          .project-title {
            @include mont-semibold;
            color: $primary-blue;
            font-size: 2rem;
          }
          .project-details {
            margin-top: 2rem;
            color: $mid-gray;
            p {
              margin-bottom: 1rem;
              &:not(:first-of-type) {
                @include mont-medium;
                color: $secondary-blue;
              }
            }
          }
          a {
            font-family: inherit;
            color: $mid-gray;
            text-decoration: none;
            &:hover {
              color: $primary-blue;
            }
          }
        }
        .project-display-container {
          position: relative;
          max-width: 400px;
          overflow: hidden;
          flex-shrink: 0;
          img {
            transition: 0.6s ease;
            width: 100%;
            height: 100%;
          }
          .project-display-hover-overlay {
            transition: 0.4s ease;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: white;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            a {
              color: $primary-blue;
              &:first-of-type {
                margin-right: 1rem;
              }
              &:hover {
                color: white;
              }
            }
          }
          &:hover {
            cursor: pointer;
            img {
              transition: 0.6s ease;
              transform: scale(1.2);
            }
            .project-display-hover-overlay {
              transition: 0.4s ease;
              opacity: 0.9;
            }
          }
        }
      }
    }
  }
}

//Animations
@keyframes pulse {
  0% {
    opacity: 0.2;
    font-size: 1.5rem;
  }
  50% {
    opacity: 1;
    font-size: 2.5rem;
  }
  100% {
    opacity: 0.2;
    font-size: 1.5rem;
  }
}
