@import url("https://fonts.googleapis.com/css?family=Montserrat:100,300,400,500,600,700&display=swap");

@mixin mont-thin {
    font-family: "Montserrat", sans-serif;
    font-weight: 100;
}

@mixin mont-light {
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
}

@mixin mont-regular {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
}

@mixin mont-medium {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
}

@mixin mont-semibold {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
}

@mixin mont-bold {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
}
